import React, { Fragment, useEffect, useRef, useState } from "react"
import HailCXLayout from "../../components/layout/hailcx-layout"
import HailCXRightImageHeader from "../../components/layout/header/hailcx-right-image-header"
import HailCXDoubleButtonFooter from "../../components/layout/footer/hailcx-double-button-footer"
import {
  EuiButton,
  EuiDescriptionList,
  EuiImage,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPanel,
  EuiSpacer,
  EuiText,
} from "@elastic/eui"
import Images from "../../images"
import { navigate } from "gatsby-link"
import { observer } from "mobx-react-lite"
import { useStore } from "../../stores"
import { RepairTypes } from "../../entities/constants"
import SummaryItem from "../../entities/summaryItems"
import "../../styles/hailcx-description-lists.css"
import {
  getBookingDateTimeUIFormatted,
  getCompletionDateUIFormatted,
} from "../../entities/utilities"
import HailCXRepairTypeAlert from "../../components/hailcx-repairtype-alert"
import SummaryRepairLocation from "../../components/booking/summary-repairlocation"
import { EuiHeaderAlert } from "@elastic/eui/src/components/header/header_alert/header_alert"
import HailcxModal from "../../components/hailcx-modal"
import CustomerStore from "../../stores/customerStore"

const WaitlistConfirmRescheduledPage = () => {
  const [bookingSummary, setBookingSummary] = useState<SummaryItem[]>([])
  const { facilityStore, bookingStore, waitlistStore, customerStore } =
    useStore()
  const { facility } = facilityStore
  const { booking, rescheduleBooking, bookingResult, currentBooking } =
    bookingStore
  const { unlockSlot } = waitlistStore
  const [showWarning, setShowWarning] = useState(false)
  const [isSelectionTimeUp, setIsSelectionTimeUp] = useState(false)

  let warningInvertal: NodeJS.Timeout, fullTimerInterval: NodeJS.Timeout
  let timerIntervalInSeconds: number = 240 //4 mins
  let countdownTimerObj: NodeJS.Timer

  if (waitlistStore.lockSlotResponse?.lockedTimeInSeconds)
    timerIntervalInSeconds = waitlistStore.lockSlotResponse?.lockedTimeInSeconds

  let startTimeLeftInMilliseconds: number =
    new Date().getTime() + timerIntervalInSeconds * 1000
  const [countdownTimeLeftInMilliseconds, setCountdownTimerTime] = useState(
    timerIntervalInSeconds * 1000
  )
  const countdownTimerRef = useRef(startTimeLeftInMilliseconds)

  const data = {
    title: "Confirmation",
    primaryContent: "Confirm booking",
    secondaryContent: "Back",
  }

  useEffect(() => {
    //console.log(`eqn=${customerStore.encryptedQuoteNumber}&customerId=${customerStore.customer?.id}`);

    if (bookingResult && currentBooking && booking) {
      let staleActiveBookingInMemory,
        staleBookingSelectionScreen: boolean = false

      staleActiveBookingInMemory = bookingResult.id !== currentBooking.bookingId

      staleBookingSelectionScreen =
        booking.repairType === currentBooking.repairType &&
        booking.repairDate === currentBooking.repairDate &&
        booking.dropOffTime === currentBooking.dropOffTime

      if (staleActiveBookingInMemory || staleBookingSelectionScreen) {
        alert(
          "We noticed you have updated your booking. \nWe are redirecting you to the updated booking."
        )
        navigate(bookingResult.manageBookingUrlRelative)
        return
      }
    }
  }, [])

  useEffect(() => {
    countdownTimerRef.current = countdownTimeLeftInMilliseconds
    //console.log(`countdown timer ref initial value = ${countdownTimerRef.current}`);
  })

  useEffect(() => {
    if (booking) {
      const repairType = RepairTypes.find(
        rt => rt.acronym === booking.repairType
      )

      if (repairType) {
        const summary = [
          {
            title: <EuiImage src={Images.Clock} alt="Drop Off" />,
            description: (
              <EuiText grow={false}>
                <p>
                  <strong>Drop off</strong>
                  <br />
                  {`${getBookingDateTimeUIFormatted(
                    booking?.repairDate,
                    booking?.dropOffTime
                  )}`}
                </p>
              </EuiText>
            ),
          },
          {
            title: <EuiImage src={Images.MapMarker} alt="Location" />,
            description: (
              <EuiText grow={false}>
                <SummaryRepairLocation facility={facility} />
              </EuiText>
            ),
          },
          {
            title: <EuiImage src={Images.CalendarIcon} alt="Completion" />,
            description: (
              <EuiText grow={false}>
                <p>
                  <strong>Estimated completion</strong>
                  <br />
                  {getCompletionDateUIFormatted(
                    booking?.repairDate,
                    repairType.durationInWeeks
                  )}
                </p>
              </EuiText>
            ),
          },
          {
            title: <EuiImage src={Images.CarRepair} alt="Repair Type" />,
            description: (
              <EuiText grow={false}>
                Repair required
                <br />
                <strong>{repairType.name}</strong>
                <br />
                <p className="highlightedText">
                  {repairType.durationDescription}
                </p>
              </EuiText>
            ),
          },
        ]

        setBookingSummary([...summary])
      }
    }
  }, [])

  useEffect(() => {
    //start the timer, that calls a function to show warning after it elapses
    if (!showWarning) {
      console.log("starting timer for warning")
      //show warning 1 min before the timer elapses
      warningInvertal = setTimeout(
        showWarningMessage,
        1000 * (timerIntervalInSeconds - 60)
      )
    }

    //start another timer, that calls a function that redirects user once it elapses
    if (!isSelectionTimeUp) {
      console.log("starting timer for redirect")
      fullTimerInterval = setTimeout(
        redirectBacktoSlotSelection,
        1000 * timerIntervalInSeconds
      )
    }

    //start countdown timer
    countdownTimerObj = setInterval(countdownTheTimerFunc, 1000)

    //clean up
    return () => {
      console.log("cleaning up...")

      console.log("clearing warning timer")
      clearTimeout(warningInvertal)

      console.log("clearing full timer")
      clearTimeout(fullTimerInterval)

      clearInterval(countdownTimerObj)
    }
  }, [])

  const handlePrimaryClick = async () => {
    //reschedule
    await rescheduleBooking(true)
    //unlock selected slot
    await unlockSelectedSlot()
    //navigate to completion screen
    navigate("/book/reschedule-completed")
  }

  const unlockSelectedSlot = async () => {
    let facilityId = facility?.id as string
    let repairDate = booking?.repairDate as string
    let dropOffTime = booking?.dropOffTime as string
    let repairType = booking?.repairType as string

    await unlockSlot({
      facilityId: facilityId,
      repairDate: repairDate,
      dropOffTime: dropOffTime,
      repairType: repairType,
    })
  }

  const handleSecondaryClick = async () => {
    await redirectBacktoSlotSelection()
  }

  const showWarningMessage = () => {
    console.log("showing warning")
    setShowWarning(true)
  }

  const redirectBacktoSlotSelection = async () => {
    console.log("redirecting to slot selection")
    //unlock selected slot, as customer is being navigated back to selection of slots
    await unlockSelectedSlot()

    setIsSelectionTimeUp(true)

    //take customer to slot selection screen
    navigate("/book/waitlist")
  }

  const countdownTheTimerFunc = () => {
    countdownTimerRef.current =
      startTimeLeftInMilliseconds - new Date().getTime() //calculate time left based on current time

    setCountdownTimerTime(countdownTimerRef.current) //update the time left

    //console.log(`countdown timer after update = ${countdownTimerRef.current}`);

    //once count down time has elapsed, redirect back to slot selection
    if (
      new Date(countdownTimerRef.current).getMinutes() === 0 &&
      new Date(countdownTimerRef.current).getSeconds() === 0
    ) {
      console.log("time's up, clearing cound down timer")
      clearInterval(countdownTimerObj)
      redirectBacktoSlotSelection()
    }
  }

  const closeModal = () => {
    setShowWarning(false)
  }

  const warningAlertModal = () => {
    return (
      <EuiModal onClose={closeModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>Time is almost up!</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <p>You have about 1 min remaining to confirm your selection.</p>
          <EuiSpacer />
          <p>
            After that your selection will no longer be locked and you may loose
            it.
          </p>
          <EuiSpacer />
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButton onClick={closeModal} fill>
            Close
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    )
  }

  return (
    <>
      <HailCXLayout
        header={
          <HailCXRightImageHeader
            title={data.title}
            progressValue={85}
            vehicleInfo={customerStore.getVehicleInfo()}
          />
        }
        footer={
          <HailCXDoubleButtonFooter
            primaryContent={data.primaryContent}
            secondaryContent={data.secondaryContent}
            handlePrimaryClick={handlePrimaryClick}
            handleSecondaryClick={handleSecondaryClick}
            secondaryIconRequired={true}
          />
        }
      >
        <EuiText
          grow={false}
          className="eui-textLeft"
          style={{ marginRight: "auto", marginLeft: "auto" }}
        >
          <h1>Booking selected</h1>
          <p>
            We've reserved your selection for {timerIntervalInSeconds / 60}{" "}
            mins.
            <br />
            Time left:{" "}
            {`${new Date(countdownTimeLeftInMilliseconds)
              .getMinutes()
              .toLocaleString(undefined, {
                minimumIntegerDigits: 2,
              })}:${new Date(countdownTimeLeftInMilliseconds)
              .getSeconds()
              .toLocaleString(undefined, { minimumIntegerDigits: 2 })}`}
          </p>
        </EuiText>
        <EuiSpacer />
        <EuiPanel>
          <EuiDescriptionList type="column" listItems={bookingSummary} />
        </EuiPanel>
      </HailCXLayout>

      {showWarning && warningAlertModal()}
    </>
  )
}

export default observer(WaitlistConfirmRescheduledPage)
